<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Sorry, the domain has been changed,<br>
      check out the
      <a href="https://shimmerjordan.eu.org" target="_blank" rel="noopener">https://shimmerjordan.eu.org</a>.
    </p>
    <h3>技术博客</h3>
    <ul>
      <li><a href="https://blog.shimmerjordan.eu.org" target="_blank" rel="noopener">https://blog.shimmerjordan.eu.org</a></li>
    </ul>
    <h3>个人简历</h3>
    <ul>
      <li><a href="https://cv.shimmerjordan.eu.org" target="_blank" rel="noopener">https://cv.shimmerjordan.eu.org</a></li>
    </ul>
    <h3>随笔</h3>
    <ul>
      <li><a href="https://essay.shimmerjordan.eu.org/" target="_blank" rel="noopener">https://essay.shimmerjordan.eu.org</a></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
